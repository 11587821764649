import React, { useEffect, useState } from 'react';
import { Page } from '../shared';
import { PrimaryButton, Table, DateRangeField, useForm } from '@weave/design-system';
import { useQuery } from 'react-query';
import { onboardingIntakeFormApi } from '../onboarding/onboarding.api';
import { CSMKickoffCallMetrics } from '../onboarding/handoff-hub/handoff-hub.types';
import { css } from '@emotion/core';
import { format, parseISO } from 'date-fns';
import { theme } from '@weave/theme';
import { CSVLink } from 'react-csv';

const dateFormat = 'yyyy-MM-dd';
const startDate = format(
  new Date(new Date().setDate(new Date().getDate() - 7)),
  dateFormat
);
const endDate = format(new Date(), dateFormat);
export const CsmDashboardComponent = () => {
  let csmYesCounter = 0;
  let csmNoCounter = 0;
  const [refreshData, setRefreshData] = useState<boolean>();
  const { getFieldProps, values, reset } = useForm({
    fields: {
      dateRange: {
        type: 'dateRange',
        value: [startDate, endDate],
        maxDate: new Date().toISOString(),
      },
    },
  });

  const updatedStartDate = values?.dateRange ? values.dateRange[0] : startDate;
  const updatedEndDate = values?.dateRange ? values.dateRange[1] : endDate;
  const csmKickoffQuery = useQuery(
    ['csmDashboard'],
    () => {
      return onboardingIntakeFormApi.getCsmKickoffHandoff(
        format(new Date(updatedStartDate), dateFormat),
        new Date(updatedEndDate).toISOString().slice(0, 10)
      );
    },
    {
      select: (data: CSMKickoffCallMetrics) => data,
      retry: false,
    }
  );
  const csmReportData = csmKickoffQuery.data?.csmReport || [];

  const handleRefetch = () => {
    reset();
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    if (updatedStartDate && updatedEndDate) {
      csmKickoffQuery.refetch();
      getScheduledCount();
    }
  }, [updatedStartDate, updatedEndDate, refreshData]);

  const getScheduledCount = () => {
    for (let i = 0; i < csmReportData.length; i++) {
      if (csmReportData[i].isCsmCallScheduled === 'Yes') {
        csmYesCounter++;
      }
      if (csmReportData[i].isCsmCallScheduled === 'No') {
        csmNoCounter++;
      }
    }
    return { csmYesCounter, csmNoCounter };
  };
  getScheduledCount();

  const handleFormatCSVData = () => {
    return csmReportData.map((reportData) => {
      return {
        locationId: reportData.locationId,
        callScheduled: reportData.isCsmCallScheduled,
        submittedDate: reportData.submittedAt,
        handoffSubmittedBy: reportData.handoffSubmittedBy,
        callNotes: reportData.csmCallNotes,
      };
    });
  };

  const sortedCsmKickoffData = csmKickoffQuery.data?.csmReport.sort((a, b) => {
    return new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime();
  });

  return (
    <Page
      title="CSM Dashboard"
      headerActions={
        <div
          css={css`
            display: flex;
          `}
        >
          <PrimaryButton size="large" onClick={handleRefetch}>
            Refresh
          </PrimaryButton>
        </div>
      }
    >
      <div>
        <p css={subtitleStyle}>Onboarding Handoff CSM Weave Account Overview</p>
        <div>
          <div>
            <p css={dateTextStyle}>
              Dates selected:
              <p css={selectedText}>
                {updatedStartDate ? updatedStartDate : startDate} -{' '}
                {updatedEndDate ? updatedEndDate : endDate}
              </p>
            </p>
          </div>
          <p css={dateTextStyle}>
            Scheduled: <p css={selectedText}>{csmYesCounter}</p>
          </p>
          <p css={dateTextStyle}>
            Not Scheduled: <p css={selectedText}>{csmNoCounter}</p>
          </p>
        </div>
      </div>
      <div css={dateRangeContainerStyles}>
        <DateRangeField
          {...getFieldProps('dateRange')}
          label="Handoff submission range"
          css={dateRangeStyles}
        />
        <CSVLink
          filename={`OB KO call sch - ${
            updatedStartDate ? updatedStartDate : startDate
          } - ${updatedEndDate ? updatedEndDate : endDate}`}
          data={handleFormatCSVData()}
        >
          <PrimaryButton
            css={css`
              margin-left: ${theme.spacing(2)};
            `}
          >
            Export
          </PrimaryButton>
        </CSVLink>
      </div>

      <Table
        tableInstanceId="csm-dashboard-table"
        hasResizeColumns={true}
        colConfig={[
          {
            id: 'locationId',
            Header: 'Location ID ',
            accessor: (data) => data.locationId,
          },
          {
            id: 'isCsmCallScheduled',
            Header: 'Call Scheduled',
            maxWidth: 160,
            minWidth: 150,
            accessor: (data) => data.isCsmCallScheduled,
          },
          {
            id: 'csmCallNotes',
            Header: 'Call Notes',
            accessor: (data) => data.csmCallNotes,
          },
          {
            id: 'submittedAt',
            maxWidth: 400,
            minWidth: 200,
            Header: `Submitted Date`,
            accessor: (data) => {
              return format(parseISO(data.submittedAt), 'MM/dd/yyyy');
            },
          },
          {
            id: 'handoffSubmittedBy',
            Header: 'Handoff Submitted By',
            accessor: (data) => data.handoffSubmittedBy,
          },
        ]}
        data={sortedCsmKickoffData ?? []}
      ></Table>
    </Page>
  );
};

const subtitleStyle = css`
  font-size: ${theme.fontSize(20)};
  margin-bottom: ${theme.spacing(1)};
`;
const dateTextStyle = css`
  display: flex;
  padding-left: ${theme.spacing(2)};
  margin-bottom: 0;
`;
const selectedText = css`
  font-weight: bold;
  padding-left: ${theme.spacing(1)};
`;

const dateRangeContainerStyles = css`
  display: flex;
  justify-content: flex-start;
`;

const dateRangeStyles = css`
  div {
    min-width: 315px;
  }
`;
