import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EditIcon,
  FormRow,
  IconButton,
  SpinningLoader,
  SyncIcon,
  TextLink,
  TrashIcon,
  CheckboxField,
  TextareaField,
  useForm,
  Text,
  PrimaryButton,
  CopyIcon,
  ExternalLinkIcon,
  InfoIcon,
  NakedButton,
  PostalCodeLocale,
  FormFieldActionTypes,
} from '@weave/design-system';
import {
  MediaType as OldMediaType,
  PhoneBillDownloadPayload,
  PortCreationFileResponseData,
  PortViewFileUploadResponse,
  RequestClient,
  UploadLOAMediaType,
  ValidationData,
  loaStatus,
  userData,
  renderItemValueType,
} from 'apis/porting/porting.types';
import {
  PortingData,
  MediaData,
  PortingDataUpdateRequest,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import {
  PortingType,
  PortStatus,
  MediaType,
} from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { portingCardStyles } from './porting-card.styles';
import { theme } from '@weave/theme-original';
import { useAlert } from '@weave/alert-system';
import { PortOrderUploadComponent } from '../port-order/port-order-upload.component';
import {
  portingApi as API,
  portViewBillDownload,
  portViewBillsUpload,
  portViewLoaDownload,
  portViewLoaUpload,
  postLoaData,
  getLoaState,
  getUserData,
  generateInternalLoa,
} from 'apis/porting/porting.api';
import fileDownload from 'js-file-download';
import { LoaderModal } from '../utils/loader.component';
import { FileButton } from '../port-order/port-order.component';
import { useDropzone } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { css } from '@emotion/core';
import { CountryCodes } from '../utils/port-constants';
import { downloadLOA } from 'redux/actions/porting';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/actions/auth/user/user.action';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
} from 'reactstrap';
import { format } from 'date-fns';
import { convertedString } from '../utils/porting-utils';
import { Store } from 'redux/store/store.model';
import { DiffModalComponent } from '../utils/diff-modal-component';

type SortedFiles = { accepted: File[]; rejected: File[]; duplicates: File[] };

interface Props {
  port: PortingData;
  canEditAttachments: boolean;
  setCanEditAttachments: React.Dispatch<React.SetStateAction<boolean>>;
  handleDrawerToggle: (
    drawer: string,
    toggleState: boolean,
    disableToggle: boolean
  ) => void;
  updatePortingData: (req: PortingDataUpdateRequest) => void;
  documentError?: ValidationData[];
}

export const PortingCardAttachments = (props: Props) => {
  const {
    port,
    canEditAttachments,
    setCanEditAttachments,
    handleDrawerToggle,
    updatePortingData,
    documentError,
  } = props;
  const portingRequest = port?.portingRequests?.[0];
  const alert = useAlert();
  const user = useSelector((state: any) => state.user);
  const username = useSelector((state: Store) => state.auth.user.username);
  const [loaData, setLoaData] = useState<loaStatus>();
  const [adminUserData, setAdminUserData] = useState<userData>();
  const [phoneSystemOpen, setPhoneSystemOpen] = useState<boolean>(false);
  const [showLoaDiffModal, setShowLoaDiffModal] = useState(false);

  useEffect(() => {
    dispatch(getUser({ username }));
  }, [username]);

  const getLoaStateData = async () => {
    if (!port?.id) {
      console.error('Porting ID missing for getting LOA state.');
      return;
    }

    try {
      const response = await getLoaState(port?.id);
      setLoaData(response);
    } catch (error) {
      alert.error(`${error}`);
    }
  };

  useEffect(() => {
    getLoaStateData();
  }, []);

  const getAdminData = async () => {
    try {
      const email = loaData?.adminEmail;
      if (email) {
        const res = await getUserData(email);
        setAdminUserData(res);
      }
    } catch (error) {
      alert.error(`${error}`);
    }
  };

  useEffect(() => {
    getAdminData();
  }, [loaData]);

  useEffect(() => {
    if (port?.customerPhoneBillMedia) {
      const loaFiles = port?.customerPhoneBillMedia
        ?.filter(
          (item) =>
            item.mediaType === MediaType.MEDIA_TYPE_LOA ||
            item.mediaType === OldMediaType.LOA
        )
        .sort((file1, file2) => {
          if (!!file1.updatedAt && !!file2.updatedAt)
            return new Date(file1.updatedAt) < new Date(file2.updatedAt) ? 1 : -1;
          return 0;
        });
      setAttachedLOA(loaFiles ?? []);
      const bills = port?.customerPhoneBillMedia?.filter(
        (item) =>
          item.mediaType === MediaType.MEDIA_TYPE_PHONE_BILL ||
          item.mediaType === OldMediaType.phoneBill
      );
      setAttachedBills(bills ?? []);
    }
  }, [port.customerPhoneBillMedia]);

  const [loaLoader, setLoaLoader] = useState<boolean>(false);
  const [billLoader, setBillLoader] = useState<boolean>(false);
  const [attachedLOA, setAttachedLOA] = useState<MediaData[]>([]);
  const [attachedBills, setAttachedBills] = useState<MediaData[]>([]);
  const [downloadLOALoading, setDownloadLOALoading] = useState<boolean>(false);
  const [expiredValue, setExpiredValue] = useState<boolean>(false);
  const dispatch = useDispatch();

  const country = port?.serviceCountry;
  const checkCountryValue: boolean = country !== PostalCodeLocale.US;

  const {
    getFieldProps,
    values: fields,
    isComplete,
  } = useForm({
    fields: {
      noBillAvailable: {
        type: 'checkbox',
        value: port.noBillAvailable,
      },
      noBillReason: {
        type: 'text',
        value: port.reasonBillNotAvailable,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        switch (action.payload.name) {
          case 'noBillReason': {
            if (checkCountryValue)
              return {
                ...state,
                noBillReason: {
                  ...state.noBillReason,
                  required: true,
                },
              };
          }
        }
      }
      return null;
    },
  });
  const noBillProps = getFieldProps('noBillAvailable');
  const phoneBillList = port?.customerPhoneBillMedia?.filter(
    (media) =>
      media.mediaType === MediaType.MEDIA_TYPE_PHONE_BILL ||
      media.mediaType === OldMediaType.phoneBill
  );
  const todayDate = new Date();

  const isDateExpired = (date): boolean => {
    /**it check for the expiration date from date to one month ahead */
    const expirationDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    return todayDate > new Date(expirationDate);
  };

  const IsLoaExpired = (): boolean => {
    const createdDate = port?.customerPhoneBillMedia?.find(
      (item) => item.mediaType === 'loa'
    )?.createdAt;

    if (!createdDate) return false;
    const loaCreatedDate = new Date(createdDate);
    if (isDateExpired(loaCreatedDate)) {
      alert.error('Attached LOA is expired. Please attach new document');
      return true;
    }
    return false;
  };
  const invalidStatusForExpiredTag = [
    PortStatus.PORT_STATUS_CANCELLED,
    PortStatus.PORT_STATUS_COMPLETED,
    PortStatus.PORT_STATUS_DELETED,
    PortStatus.PORT_STATUS_ARCHIVED,
  ];

  const showExpiredTag = () => {
    if (portingRequest?.portingStatus) {
      const portStatus = portingRequest?.portingStatus;
      return !invalidStatusForExpiredTag.includes(portStatus);
    }
    return true;
  };

  useEffect(() => {
    showExpiredTag() && setExpiredValue(IsLoaExpired());
  }, []);

  const invalidCancellationPortStatuses = [
    PortStatus.PORT_STATUS_CANCELLED,
    PortStatus.PORT_STATUS_CANCEL_PENDING,
    PortStatus.PORT_STATUS_CANCEL_SUBMITTED,
    PortStatus.PORT_STATUS_REQUESTED_CANCEL,
    PortStatus.PORT_STATUS_DELETED,
    PortStatus.PORT_STATUS_ARCHIVED,
    PortStatus.PORT_STATUS_SPLIT_PORT,
  ];

  const showSendLOAButton = () => {
    if (portingRequest?.portingStatus) {
      const portStatus = portingRequest?.portingStatus;

      if (invalidCancellationPortStatuses.includes(portStatus)) {
        return false;
      }
    }
    return true;
  };

  const isSameFileAlreadyUploaded = (file: File, currentFiles: MediaData[]) => {
    return currentFiles.some((item) => item.fileName === file.name);
  };

  const copyToClipBoard = async (copyUrl) => {
    try {
      await navigator.clipboard.writeText(copyUrl);
      alert.success('Copied to clipboard');
    } catch (err) {
      alert.error('Some error occurred while copying');
    }
  };

  const validateFile = (acceptedFiles: File[], currentFiles: MediaData[]) => {
    if (acceptedFiles.length > 0) {
      const sortedFiles = acceptedFiles.reduce(
        (acc, file) => {
          const isDuplicate = isSameFileAlreadyUploaded(file, currentFiles);
          if (file.size <= 3e6 && !isDuplicate) {
            return { ...acc, accepted: [...acc.accepted, file] };
          } else if (isDuplicate) {
            return { ...acc, duplicates: [...acc.duplicates, file] };
          } else {
            return { ...acc, rejected: [...acc.rejected, file] };
          }
        },
        { accepted: [], rejected: [], duplicates: [] } as SortedFiles
      );
      return sortedFiles;
    }
    return { accepted: [], rejected: [], duplicates: [] } as SortedFiles;
  };

  const showAlerts = (sortedFile: SortedFiles) => {
    sortedFile.rejected.forEach((file) => {
      alert.error(`Error uploading file: ${file.name}`);
    });
    sortedFile.duplicates.forEach((file) => {
      alert.error(
        `The file with name ${file.name} has been already uploaded. Please upload with different name.`
      );
    });
  };

  const handleFileUpload = async (file: File, type: OldMediaType) => {
    if (file !== undefined) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        const data = await API.uploadPhoneFile(formData);
        alert.success(`Successfully submitted file: ${file.name}`);
        return data;
      } catch {
        alert.error('Error uploading file.');
        return null;
      }
    }
    return null;
  };

  const onLoaDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!port.id) {
        console.error('Porting ID missing for uploading LOA.');
        return;
      }

      setLoaLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedLOA);
      if (sortedFile.accepted.length > 0) {
        try {
          const responseData: PortCreationFileResponseData | null =
            await handleFileUpload(sortedFile?.accepted?.[0], OldMediaType.LOA);
          if (responseData?.ID) {
            const payload: UploadLOAMediaType = {
              porting_data_id: port.id,
              media_id: responseData?.ID,
              file_name: responseData?.Name,
              media_type: OldMediaType.LOA,
            };
            const response: PortViewFileUploadResponse | null = await portViewLoaUpload(
              port.id,
              payload
            );
            const mediaData: MediaData = response.media_data;
            if (mediaData?.id) {
              setAttachedLOA([mediaData]);
              updatePortingMediaData([mediaData], [...attachedBills]);
            }
          }
        } catch (err) {
          alert.error(`Error in uplaoding file: ${err.message}`);
        }
      }
      setLoaLoader(false);
      showAlerts(sortedFile);
    },
    [attachedLOA, attachedBills, port.customerPhoneBillMedia]
  );

  const updatePortingMediaData = (loas: MediaData[], bills: MediaData[]) => {
    const portCopy = cloneDeep(port);
    portCopy.customerPhoneBillMedia = [...loas, ...bills];
    if (bills.length !== 0) {
      portCopy.reasonBillNotAvailable = '';
      portCopy.noBillAvailable = !!portCopy.reasonBillNotAvailable;
    }
    updatePortingData({ portingData: portCopy, portingDataId: portCopy.id });
  };

  const removeLOA = (file: MediaData) => {
    setAttachedLOA((prevFiles) => {
      const tempIndex = prevFiles.findIndex((item) => item.mediaId === file.mediaId);
      const replacedFile = cloneDeep(prevFiles);
      if (tempIndex >= 0) replacedFile.splice(tempIndex, 1);
      updatePortingMediaData([...replacedFile], [...attachedBills]);
      return replacedFile;
    });
  };

  const removeBills = (file: MediaData) => {
    const tempIndex = attachedBills.findIndex((item) => item.mediaId === file.mediaId);
    attachedBills.splice(tempIndex, 1);
    setAttachedBills([...attachedBills]);
    updatePortingMediaData([...attachedLOA], [...attachedBills]);
  };

  const onRemoveFile = async (file: MediaData) => {
    if (!file.id || !port.id) {
      console.error('Porting ID or Media ID missing for deleting file.');
      return;
    }
    try {
      await API.portViewFileDelete(port.id, file.id);
      if (file.mediaType === OldMediaType.LOA) {
        removeLOA(file);
      } else {
        removeBills(file);
      }
    } catch {
      alert.error(`deletion failed: ${file.fileName}`);
    }
  };

  const { getInputProps, open } = useDropzone({
    accept: '.pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: onLoaDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    maxSize: 3e6,
    multiple: false,
  });

  const onBillsDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length + attachedBills.length > 5) {
        alert.error('You can add a maximum of 5 previous bills');
        return;
      }
      setBillLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedBills);
      sortedFile.accepted.forEach(async (file) => {
        if (!port.id) {
          console.error('Porting ID missing for uploading bill.');
          return;
        }

        try {
          const responseData: PortCreationFileResponseData | null =
            await handleFileUpload(file, OldMediaType.phoneBill);
          if (responseData?.ID) {
            const payload: UploadLOAMediaType = {
              porting_data_id: port.id,
              media_id: responseData?.ID,
              file_name: responseData?.Name,
              media_type: OldMediaType.phoneBill,
            };
            const response: PortViewFileUploadResponse | null = await portViewBillsUpload(
              port.id,
              payload
            );
            const mediaData: MediaData = response.media_data;
            if (mediaData?.id) {
              setAttachedBills([...attachedBills, mediaData]);
              updatePortingMediaData([...attachedLOA], [...attachedBills, mediaData]);
            }
          }
        } catch (err) {
          alert.error(err.message);
        }
      });
      setBillLoader(false);
      showAlerts(sortedFile);
    },
    [attachedBills, attachedLOA, port.customerPhoneBillMedia]
  );

  const handleDownloadLOA = async (filename: string) => {
    const { id } = port;
    if (!id) {
      console.error('Porting ID missing for downloading LOA.');
      return;
    }

    setDownloadLOALoading(true);
    try {
      const response = await portViewLoaDownload(id);
      const file = response.data;
      fileDownload(file, filename, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setDownloadLOALoading(false);
    }
  };

  const handleDownloadBill = async (mediaObj: MediaData) => {
    const { locationId } = port;
    const { mediaId, mediaType, fileName } = mediaObj;

    if (!locationId || !mediaId || !mediaType || !fileName) {
      console.error('Data missing for downloading bill.');
      return;
    }

    const payload: PhoneBillDownloadPayload = {
      customer_phone_bill_media_id: mediaId,
      customer_phone_bill_media_type: mediaType as OldMediaType,
      location_id: locationId,
    };
    setDownloadLOALoading(true);
    try {
      const response = await portViewBillDownload(payload);
      const file = response.data;
      fileDownload(file, fileName, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setDownloadLOALoading(false);
    }
  };

  const handleUpdatePortingDetails = () => {
    const portCopy = cloneDeep(port);
    portCopy.noBillAvailable = attachedBills.length === 0 ? true : false;
    portCopy.reasonBillNotAvailable =
      attachedBills.length === 0 ? fields.noBillReason ?? '' : '';
    portCopy.customerPhoneBillMedia = [...attachedLOA, ...attachedBills];
    updatePortingData({ portingData: portCopy, portingDataId: portCopy.id });
  };

  const haveEditAccess = useMemo(() => {
    const noEditAccessStatuses = [
      PortStatus.PORT_STATUS_CANCEL_PENDING,
      PortStatus.PORT_STATUS_CANCELLED,
      PortStatus.PORT_STATUS_ACCEPTED,
      PortStatus.PORT_STATUS_FOC,
      PortStatus.PORT_STATUS_REQUESTED_CANCEL,
      PortStatus.PORT_STATUS_ACTIVATION_IN_PROGRESS,
    ];
    return !noEditAccessStatuses.includes(
      portingRequest?.portingStatus ?? ('' as PortStatus)
    );
  }, [portingRequest]);

  const getValueForDisableEditIcon = (): boolean => {
    if (port?.serviceCountry === CountryCodes.Canada.Code) {
      return (
        canEditAttachments &&
        (!port?.reasonBillNotAvailable || !fields.noBillReason) &&
        attachedBills?.length === 0
      );
    }
    return false;
  };

  const hasNoBillAvailable = port?.noBillAvailable;
  const hasPhoneBillList = phoneBillList && phoneBillList?.length === 0;
  const hasCustomerBillMedia = !port?.customerPhoneBillMedia;
  const closeLoaDiffModal = () => setShowLoaDiffModal(false);

  const sendLoaRelatedData = async () => {
    if (!port?.id) {
      console.error('Missing port.id for sending LOA.');
      return;
    }

    try {
      await postLoaData(port?.id, user?.FirstName, user?.LastName, user?.Username);
      alert.success(`Sent LOA mail to ${port?.officeEmail}`);
      getLoaStateData();
    } catch (error) {
      const errorData = error?.response?.data?.match(/.*"messages":\["(?<message>.*?)"/);
      const errorCause = errorData?.groups?.message;
      alert.error(`${errorCause}`);
    }
  };

  const formatDate = (date: string, showMins: boolean) => {
    if (showMins) return format(new Date(date), 'dd-MMM-yy hh:mm a');
    return format(new Date(date), 'dd-MMM-yy');
  };

  const portingStatus = portingRequest?.portingStatus
    ? portingRequest?.portingStatus
    : 'Draft';

  const displayDataForLoa = (value, key) => {
    if (
      typeof value === 'string' &&
      ['createdAt', 'expirationDate', 'firstViewedAt', 'lastViewedAt'].includes(key)
    ) {
      return formatDate(value, true);
    }
    if (typeof value === 'object') {
      return (
        <>
          <Text
            css={css`
              margin-bottom: 5px;
            `}
          >
            {value?.signedName && 'Signed Name: '} {value?.signedName}
          </Text>
          <Text>
            {value?.signDate && 'Sign Date: '} {value?.signDate}
          </Text>
        </>
      );
    }
    if (key === 'portingStatusWhenGenerated') {
      return portingStatus;
    }
    return value;
  };

  const renderDropdownItem = (key: string, value: renderItemValueType['value']) => {
    return (
      <DropdownItem>
        <NavItem>
          <span
            css={css`
              cursor: not-allowed;
            `}
          >
            {key === 'signedDetails' || key === 'documentMetadata' ? null : (
              <span
                css={css`
                  text-transform: capitalize;
                `}
              >
                {convertedString(key) + ': '}
              </span>
            )}
            {displayDataForLoa(value, key)}
          </span>
        </NavItem>
      </DropdownItem>
    );
  };

  const sendLoaButton = (buttonLabel: string) => {
    return (
      <PrimaryButton size="tiny" onClick={sendLoaRelatedData}>
        {buttonLabel}
      </PrimaryButton>
    );
  };

  const getLoaInfoIcon = () => {
    return (
      <>
        <Dropdown
          isOpen={phoneSystemOpen}
          toggle={() => setPhoneSystemOpen(!phoneSystemOpen)}
        >
          <DropdownToggle
            style={{
              background: 'none',
              border: 'none',
              width: '0px',
              boxShadow: 'none',
              height: '0px',
              marginRight: `${theme.spacing(0.5)}`,
            }}
          >
            <InfoIcon size={14} className={portingCardStyles.infoIcon}></InfoIcon>;
          </DropdownToggle>
          <DropdownMenu>
            {loaData &&
              Object.entries(loaData).map(([key, value]) => (
                <span
                  key={key}
                  css={css`
                    cursor: not-allowed !important;
                  `}
                >
                  {value &&
                    key !== 'loaLinkGenerated' &&
                    key !== 'documentMetadata' &&
                    renderDropdownItem(key, value)}
                </span>
              ))}
          </DropdownMenu>
        </Dropdown>
        <ExternalLinkIcon
          size={14}
          className={portingCardStyles.externalLinkIcon}
          onClick={() => window.open(loaData?.loaUrl, '_blank')}
        ></ExternalLinkIcon>
        <CopyIcon
          className={portingCardStyles.copyIcon}
          size={14}
          onClick={() => copyToClipBoard(loaData?.loaUrl)}
        ></CopyIcon>
        {sendLoaButton('Send LOA')}
      </>
    );
  };

  const signInternalPortLoa = async () => {
    if (!port?.id || !user?.Username) {
      console.error('Porting ID or Username missing for signing LOA.');
      return;
    }

    try {
      await generateInternalLoa(port?.id, user?.Username);
      alert.success(
        'Sign LOA successfully triggered. Please refresh the page after few minutes to view the document.'
      );
    } catch (error) {
      alert.error(
        'Failed to Self Sign LOA. We are on it. Please try Send LOA to manually attach LOA.'
      );
    }
  };

  const showLoaDetails = () => {
    if (!loaData?.loaLinkGenerated) return sendLoaButton('Send LOA');
    if (todayDate > new Date(loaData?.expirationDate)) {
      return (
        <div css={{ display: 'flex' }}>
          <Text color="error" className={portingCardStyles.loaExpired}>
            LOA link Expired
          </Text>
          {sendLoaButton('Generate LOA')}
        </div>
      );
    }
    if (loaData?.signedDetails) {
      return (
        <>
          <Text
            color="light"
            css={css`
              display: flex;
            `}
          >
            LOA Signed by Customer dated:
            {' ' + loaData?.signedDetails?.signDate}
            <span
              css={css`
                display: flex;
                cursor: pointer;
              `}
            >
              {getLoaInfoIcon()}
            </span>
          </Text>
        </>
      );
    }
    if (!loaData?.signedDetails) {
      return (
        <div
          css={css`
            display: flex;
          `}
        >
          <Text color="light">
            {adminUserData?.FirstName} sent LOA and waiting on Customer
          </Text>
          <span
            css={css`
              display: flex;
              cursor: pointer;
            `}
          >
            {getLoaInfoIcon()}
          </span>
        </div>
      );
    }
    return sendLoaButton('Send LOA');
  };

  return (
    <div>
      <div className={portingCardStyles.portingInfoContainer}>
        <div className={portingCardStyles.title}>Documents</div>
        {(!portingRequest?.portOrderNumber || haveEditAccess) && (
          <div>
            <EditIcon
              css={css`
                color: ${!canEditAttachments && theme.colors.weaveBlue};
              `}
              onClick={() =>
                handleDrawerToggle(
                  'attachments',
                  canEditAttachments,
                  getValueForDisableEditIcon()
                )
              }
            />
          </div>
        )}
      </div>
      {documentError?.map((data) => (
        <Text color="error" key={data.id}>
          {data.reason}
        </Text>
      ))}

      <div className={portingCardStyles.officeCont}>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 250px;
              margin-bottom: 10px;
            `}
          >
            LOA
          </div>
          <div>
            {canEditAttachments && !!attachedLOA && (
              <FormRow>
                {attachedLOA.length <= 0 && (
                  <PortOrderUploadComponent onFileDrop={onLoaDrop} />
                )}
                {attachedLOA.length > 0 && (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    {attachedLOA.map((item, index) => {
                      return (
                        <div
                          key={item.fileName}
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <TextLink
                            css={css`
                              padding-right: 10px;
                              margin: 0;
                            `}
                            onClick={() => {
                              handleDownloadLOA(item?.fileName ?? '');
                            }}
                          >
                            {item.fileName}
                          </TextLink>
                          {attachedLOA.length === 1 ? (
                            <IconButton
                              label="Replace file"
                              size="small"
                              showLabelOnHover
                            >
                              <SyncIcon
                                onClick={() => open()}
                                css={css`
                                  cursor: pointer;
                                `}
                                color="light"
                              />
                            </IconButton>
                          ) : (
                            <IconButton label="Delete file" size="small">
                              <TrashIcon
                                onClick={() => onRemoveFile(item)}
                                css={css`
                                  cursor: pointer;
                                `}
                                color="light"
                              />
                            </IconButton>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!!loaLoader && <SpinningLoader size="small" />}
              </FormRow>
            )}
          </div>

          {showSendLOAButton() && !canEditAttachments && <>{showLoaDetails()}</>}
        </div>

        <div>
          {!canEditAttachments &&
            attachedLOA.length === 0 &&
            (port.requestClient !== RequestClient.WAM ? (
              <div css={{ display: 'flex' }}>
                <div className={portingCardStyles.documentStyle}></div>
                {port?.requestClient !== RequestClient.dashboard &&
                  port?.portType !== PortingType.PORTING_TYPE_INTERNAL && (
                    <TextLink
                      css={css`
                        padding-right: ${theme.spacing(1)};
                        margin: 0;
                      `}
                      onClick={() => {
                        dispatch(downloadLOA(port));
                      }}
                    >
                      Download Auto generated LOA
                    </TextLink>
                  )}
              </div>
            ) : (
              <div css={{ display: 'flex' }}>
                <div className={portingCardStyles.documentStyle}></div>
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                  {port?.portType === PortingType.PORTING_TYPE_INTERNAL && (
                    <TextLink onClick={() => signInternalPortLoa()}>
                      Sign Internal Port
                    </TextLink>
                  )}
                  <Text color="light"> No files attached</Text>
                </div>
              </div>
            ))}
        </div>

        {!canEditAttachments && !!attachedLOA && attachedLOA.length > 0 && (
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                width: 250px;
              `}
            ></div>
            <div>
              {attachedLOA.map((item, index) => {
                return (
                  <div
                    key={item.fileName}
                    css={css`
                      display: flex;
                      margin-bottom: ${theme.spacing(1)};
                    `}
                  >
                    <TextLink
                      css={css`
                        padding-right: 10px;
                        margin: 0;
                      `}
                      onClick={() => {
                        handleDownloadLOA(item?.fileName ?? '');
                      }}
                    >
                      {item.fileName}
                    </TextLink>
                    {loaData?.documentMetadata?.mediaId && (
                      <NakedButton
                        color="light"
                        className={portingCardStyles.diffStyle}
                        onClick={() => setShowLoaDiffModal(true)}
                      >
                        <Text
                          as="span"
                          color="light"
                          css={{ padding: `${theme.spacing(0.5)}` }}
                          className={portingCardStyles.loaExpired}
                        >
                          Show Diff
                        </Text>
                      </NakedButton>
                    )}
                    <DiffModalComponent
                      showLoaDiffModal={showLoaDiffModal}
                      closeLoaDiffModal={closeLoaDiffModal}
                      initialData={loaData?.documentMetadata?.initialData || undefined}
                      finalData={loaData?.documentMetadata?.finalData || undefined}
                    />
                    {showExpiredTag() && expiredValue && (
                      <Text color="error" className={portingCardStyles.expiredValue}>
                        Expired
                      </Text>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 250px;
              margin-bottom: 10px;
            `}
          >
            Previous Bill
          </div>
          <div>
            {canEditAttachments && (
              <FormRow>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                    `}
                  >
                    <PortOrderUploadComponent
                      onFileDrop={onBillsDrop}
                      canUploadMutipleFiles={true}
                    />
                    {!!billLoader && <SpinningLoader size="small" />}
                  </div>
                  {attachedBills.map((item, index) => {
                    return (
                      <FileButton
                        key={item.fileName}
                        file={item}
                        onRemove={onRemoveFile}
                        onDownload={handleDownloadBill}
                      ></FileButton>
                    );
                  })}
                </div>
              </FormRow>
            )}
            {!canEditAttachments && attachedBills.length > 0 && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                `}
              >
                {attachedBills.map((item, index) => {
                  return (
                    <div
                      key={item.fileName}
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <TextLink
                        css={css`
                          padding-right: 10px;
                          margin: 0;
                        `}
                        onClick={() => {
                          handleDownloadBill(item);
                        }}
                      >
                        {item.fileName}
                      </TextLink>
                    </div>
                  );
                })}
              </div>
            )}
            {attachedBills.length === 0 && (
              <>
                <CheckboxField
                  {...noBillProps}
                  name="noBillAvailable"
                  disabled={true}
                  label="No Bill Available"
                  css={css`
                    margin-bottom: 20px;
                  `}
                ></CheckboxField>
                {(hasNoBillAvailable ||
                  (hasPhoneBillList && canEditAttachments) ||
                  (hasCustomerBillMedia && canEditAttachments)) && (
                  <TextareaField
                    {...getFieldProps('noBillReason')}
                    name="noBillReason"
                    disabled={!canEditAttachments}
                    label="Why isn't there a bill?"
                    css={css`
                      margin-top: 16px;
                      height: 80px;
                    `}
                  ></TextareaField>
                )}
              </>
            )}
          </div>
        </div>

        {canEditAttachments && attachedBills.length === 0 && (
          <div className={portingCardStyles.saveOfficeCont}>
            <PrimaryButton
              className={portingCardStyles.saveOffice}
              color="blue"
              size="large"
              onClick={() => {
                setCanEditAttachments(false);
                handleUpdatePortingDetails();
              }}
              disabled={!isComplete}
            >
              Save
            </PrimaryButton>
          </div>
        )}
      </div>

      <input
        {...getInputProps()}
        data-trackingid="insys-portal-phoneBillUploadStep-input-phoneBillUpload"
      />

      <LoaderModal showLoading={downloadLOALoading} message={'downloading...'} />
    </div>
  );
};
