import { UseQueryOptions, useQuery } from 'react-query';
import { getPhoneNumbers } from './porting.api';
import {
  ExtendedRelationalPhoneNumber,
  ListPhoneNumbersRequest,
  ListPhoneNumbersResponse,
  NumberType,
} from './porting.types';
import { formatPhoneFaxNumber, formatPhoneNumber } from 'helpers/format-phone';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';

const PortingQueryKeys = {
  baseQueryKey: ['porting'],
  getPhoneNumbers: (request: ListPhoneNumbersRequest) => [
    ...PortingQueryKeys.baseQueryKey,
    'get-phone-numbers',
    ...(request.locationIds || []),
  ],
};

const useGetPhoneNumbers = (
  request: ListPhoneNumbersRequest,
  options?: UseQueryOptions<
    ListPhoneNumbersResponse,
    unknown,
    ExtendedRelationalPhoneNumber[]
  >
) => {
  return useQuery({
    queryKey: PortingQueryKeys.getPhoneNumbers(request),
    queryFn: async () => (await getPhoneNumbers(request)).data,
    retry: false,
    ...options,
    enabled: !!request.locationIds?.length && (options?.enabled ?? true),
    select: (data) => {
      return (data.phoneNumbers ?? [])?.map((phoneNumberData) => {
        const phoneNumber =
          phoneNumberData?.number?.phoneNumber?.phoneNumber?.nationalNumber?.toString() ??
          '';
        const type = phoneNumberData?.number?.faxProvider?.id
          ? NumberType.FAX
          : NumberType.PHONE;

        const usageType =
          phoneNumberData?.number?.phoneNumber?.usageType ||
          UsageType.USAGE_TYPE_UNSPECIFIED;

        const formattedPhoneNumber =
          type === NumberType.FAX
            ? formatPhoneFaxNumber(phoneNumber)
            : formatPhoneNumber(phoneNumber);
        return {
          ...phoneNumberData,
          formattedPhoneNumber,
          phoneNumber,
          type,
          usageType,
        };
      });
    },
  });
};

export const PortingQueries = {
  PortingQueryKeys,
  useGetPhoneNumbers,
};
