import React, { useState, useEffect } from 'react';
import {
  Text,
  PrimaryButton,
  ButtonBar,
  SecondaryButton,
  Modal,
  FormRow,
  useFormField,
  DatePickerField,
  ValidatorFieldState,
  DropdownField,
  TextareaField,
  useControlledField,
} from '@weave/design-system';
import {
  convertUsDateToIsoDateFormat,
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
} from '../utils/porting-utils';
import { css } from '@emotion/core';
import { format } from 'date-fns';
import { portingSchemaApi } from '../../../apis/porting/porting.api';
import { useAlert } from '@weave/alert-system';
import { PortingData } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { PortStatus } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';

interface Props {
  minDateFoc: string;
  maxDateFoc: string;
  cancelConfirmationModalProps: any;
  closeCancelModal: () => void;
  minDate: string;
  focDates: string[];
  portingDataId: string;
  removedDateInformation: string;
  port: PortingData;
  adminEmail: string;
  updatePortDetailsByFoc: (port) => void;
}

export const SupRequestModal = (props: Props) => {
  const alert = useAlert();
  const {
    minDateFoc,
    maxDateFoc,
    cancelConfirmationModalProps,
    closeCancelModal,
    minDate,
    focDates,
    portingDataId,
    removedDateInformation: addedTimeDetailsForDates,
    port,
    adminEmail,
    updatePortDetailsByFoc,
  } = props;
  const [requestPortDateError, setRequestPortDateError] = useState<string>('');
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const fieldSubReasonProps = useFormField({ type: 'dropdown' });
  const [otherReasons, setOtherReasonsField] = useState<string>('');
  const fieldPropsAddress = useControlledField({
    type: 'text',
    value: otherReasons,
    onChange: (value) => setOtherReasonsField(value),
  });

  const startDate = new Date(minDateFoc);
  const endDate = new Date(maxDateFoc);

  const getDateArray = function (start, end) {
    const arr = new Array();
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(format(new Date(dt), 'yyyy-MM-dd'));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  const dateArr = getDateArray(startDate, endDate);

  const absent = dateArr.filter((date) => !focDates.includes(date));
  const portingRequest = port.portingRequests?.[0];

  const requestedSupPortDate = useFormField({
    type: 'datePicker',
    maxDate: maxDateFoc,
    minDate: minDateFoc,
    blackoutDates: [minDate, ...absent],

    validator: ({ value }: ValidatorFieldState<'datePicker'>) => {
      const formattedValue = convertUsDateToIsoDateFormat(value);
      const formattedMinDate = convertUsDateToIsoDateFormat(minDate);

      return formattedValue < formattedMinDate
        ? 'Requested port date cannot be set in the past'
        : '';
    },
  });

  const sendSelectedFocDate = async (
    portingDataId: string,
    selectedDate: string
  ): Promise<void> => {
    if (!portingRequest) return;

    const selectedFocDate = `${selectedDate}T${addedTimeDetailsForDates}`;
    try {
      const response = await portingSchemaApi.portInSup({
        portingDataId,
        focDate: selectedFocDate,
        comments: otherReasons,
        supReason: fieldSubReasonProps.value,
        adminEmail,
      });
      const status = response.status?.toLowerCase();

      alert.success('Raised SUP Successfully');
      if (response?.requestedFocDate)
        // Not sure why portingRequest properties are being directly mutated in this function
        // but I am keeping it the same for now as part of this refactor to avoid adding
        // any new bugs.
        portingRequest.requestedFirmOrderCommitmentDate = format(
          new Date(response?.requestedFocDate),
          'MM/dd/yy'
        );
      if (response?.actualFocDate) {
        portingRequest.firmOrderCommitmentDate = format(
          new Date(response?.actualFocDate),
          'MM/dd/yy'
        );
      }

      portingRequest.portingStatus = status as PortStatus;
      handlePortChange(port);
    } catch (error) {
      alert.error(`Failed raising SUP Request ${error}`);
    } finally {
      closeCancelModal();
    }
  };

  const handlePortChange = (port: PortingData) => {
    updatePortDetailsByFoc(port);
  };

  useEffect(() => {
    if (requestedSupPortDate.value) {
      const portDate = getUTCDateWithPortingTimeString(requestedSupPortDate.value);
      if (isHoliday(portDate) || isWeekend(portDate)) {
        setRequestPortDateError(
          'Please select date which does not fall on weekend or holiday'
        );
      } else {
        setRequestPortDateError('');
        const requestSUPDate = format(new Date(requestedSupPortDate.value), 'yyyy-MM-dd');
        const isDateAvailable = focDates.some((date) => {
          return date === requestSUPDate;
        });
        if (isDateAvailable && !!fieldSubReasonProps.value) {
          setDisableSubmitButton(isDateAvailable);
          if (fieldSubReasonProps.value !== 'Other') {
            setDisableSubmitButton(!isDateAvailable);
          } else {
            otherReasons.length >= 5 && setDisableSubmitButton(!isDateAvailable);
          }
          return;
        }
      }
    }
  }, [requestedSupPortDate.value, otherReasons, fieldSubReasonProps.value]);

  const onSubmit = () => {
    const requestSUPDate = format(new Date(requestedSupPortDate.value), 'yyyy-MM-dd');
    sendSelectedFocDate(portingDataId, requestSUPDate);
  };

  return (
    <Modal {...cancelConfirmationModalProps}>
      <Modal.Header>SUP Request</Modal.Header>
      <Modal.Body>
        <Text textAlign="center">
          To change the FOC date (porting data) for this order, please select a new date
          below. Your request will be automatically submitted to the appropriate party
          provider.
        </Text>
      </Modal.Body>
      <ButtonBar css={{ justifyContent: 'center' }}>
        <FormRow>
          <DatePickerField
            {...requestedSupPortDate}
            name="requestedSupPortDate"
            label="Port Date"
          />
        </FormRow>
      </ButtonBar>
      <div
        css={(theme) =>
          css`
            margin: 0 ${theme.spacing(9)};
          `
        }
      >
        <DropdownField name="sup_reason" label="Sup Reason" {...fieldSubReasonProps}>
          <DropdownField.Option value=" Missed Nationwide Install">
            Missed Nationwide Install
          </DropdownField.Option>
          <DropdownField.Option value="Phones Did Not Arrive">
            Phones Did Not Arrive
          </DropdownField.Option>
          <DropdownField.Option value="POC Not in Office">
            POC Not in Office
          </DropdownField.Option>
          <DropdownField.Option value="Office Not Ready To Port">
            Office Not Ready To Port
          </DropdownField.Option>
          <DropdownField.Option value="Phones Missing Power Cords">
            Phones Missing Power Cords
          </DropdownField.Option>
          <DropdownField.Option value="Install Rescheduled">
            Install Rescheduled
          </DropdownField.Option>
          <DropdownField.Option value="Missed Remote Install">
            Missed Remote Install
          </DropdownField.Option>
          <DropdownField.Option value="Network Audit Issue">
            Network Audit Issue
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To Internet">
            Numbers Tied To Internet
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To CC Machine">
            Numbers Tied To CC Machine
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To Security System">
            Numbers Tied To Security System
          </DropdownField.Option>
          <DropdownField.Option value="COVID">COVID</DropdownField.Option>
          <DropdownField.Option value="Doesn't Want Weave">
            Doesn't Want Weave
          </DropdownField.Option>
          <DropdownField.Option value="Porting Issue">Porting Issue</DropdownField.Option>
          <DropdownField.Option value=">Training Issue">
            Training Issue
          </DropdownField.Option>
          <DropdownField.Option value="Other">Other</DropdownField.Option>
        </DropdownField>
        <div
          css={(theme) =>
            css`
              margin-top: ${theme.spacing(5)};
            `
          }
        >
          {fieldSubReasonProps.value === 'Other' && (
            <TextareaField
              name="reasons"
              label="Other reason(s) if any:"
              helperText="Please enter atleast 5 characters"
              autoGrow={[1, 3]}
              {...fieldPropsAddress}
            />
          )}
        </div>
      </div>
      {requestPortDateError && (
        <Text
          color="error"
          css={(theme) =>
            css`
              text-align: center;
              margin: ${theme.spacing(0)};
              margin-top: ${theme.spacing(-2.6)};
            `
          }
        >
          {requestPortDateError}
        </Text>
      )}
      <ButtonBar>
        <SecondaryButton size="large" onClick={closeCancelModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={onSubmit} disabled={disableSubmitButton}>
          Submit
        </PrimaryButton>
      </ButtonBar>
    </Modal>
  );
};
